import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import TextContainer from "../components/text-container";
import { CtaForm } from "../components/cta-form";

export const query = graphql`
query ContactPageQuery {
  sanityContactPage(_id: {eq: "contactPage"}) {
    cta_subtitle
    cta_title
    seo {
      title
      description
      image {
        asset {
          url
        }
      }
    }
  }
}
`;

export default function ContactPage(props) {
  const page = props.data?.sanityContactPage
  return (
    <Layout title={page.seo?.title || page.cta_title} description={page.seo?.description || page.cta_subtitle} image={page.seo?.image} location="/contact">
      <TextContainer>
        <div className="text-center pb-6">
          <h1 className="pt-4 text-3xl">{page.cta_title}</h1>
          <p>{page.cta_subtitle}</p>
        </div>
        <CtaForm />
      </TextContainer>
    </Layout>
  )

}